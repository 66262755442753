.sidenav {
    width: 300px;
    background-color:  #65ccb8;
}


.logo-container {
    padding: 0 16px;
    height: 64px;
    line-height: 64px;
    font-size: 20px;
    box-sizing: content-box;
    background-color: black;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.logo-container > img {
    max-height: 100%;
}

.logo-container > i {
    line-height: inherit;
    height: 48px;
    margin: 0 16px 0 0;
    width: 20px;
    color: rgba(0,0,0,0.54);
    position: relative;
    top: 5px;
}

/* .sidenav .user-view .background img {
    width: 151px;
}

.sidenav .user-view {
    height: 150px;
} */

.collapsible-body > ul img{
    float: left;
    height: 48px;
    line-height: 48px;
    margin: 0 32px 0 0;
    width: 48px;
    color: rgba(0, 0, 0, 0.54);
}

.no-padding li {
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.section-header {
    background-color: #182628;
}

.sidenav li > a.section-header:hover  {
  background-color: #182628;
}

.section-header span {
    color: white;
}

.sidenav li > .disclaimer {
    padding: 0 16px;
    /* background-color: #b5e7a0; */
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.dropdown-content {
    background-color: white;
}

/* .dropdown-trigger, .secondary-sites-item, .data-sources-item {
    background-color: #65ccb8;
} */

.dropdown-content li span {
    color: black;
}

.sidenav-trigger {
    position: fixed;
    top: 2px;
    color: black;
}

.sidenav-trigger > i {
    font-size: 36px;
}