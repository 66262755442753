.uses {
    background-color: rgba(255, 255, 255, 0.5);
}

/* .uses-title {
    background-color: white;
} */

.uses-desc > div {
    text-align: center;
}

.rain-img {
    max-width: 100%;
}

.dashboard #mapid img {
    max-width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: relative;
    width: 50%;
}