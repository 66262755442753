.data-table {
    padding: 10px;
}

[type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid #8c9eff !important;
    border-bottom: 2px solid #8c9eff !important;
}

.spectra-only .isMetaLink-text {
    color: black;
}

.spectra-table, .multi-table, .hyper-table {
    width: 100%;
    .side-map {
        position: relative;
        z-index: -1;
    }
    tbody {
        position: relative;
        z-index: 0;
    }
}

.rgb-table-inside {
    // td, th {
    //     border: solid thin;
    // }
    th, td:not(.delete-button):not(.td-date) {
        min-width: 140px !important;
        border: solid thin;
    }
    
    .td-date{
        min-width: 140px !important;
        border: solid medium;
    }
}