#signin-error.loading:not(.error) {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
	border: 5px solid #f3f3f3;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	border-top: 5px solid #555;
	border-radius: 50%;
	width: 50px;
	height: 50px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#signin-error.error {
    display: block;
}