/* Popup container */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;

    .press-link {
      color: #039be5;
    }

    .close-image {
      font-size: 2rem;
      color: white;
      background-color: black;
      visibility: hidden;
      position: absolute;
      z-index: 3;
      top: 135%;
      left: 50%;
      // margin-left: 250px;
    }

    .handle {
      padding: 5px;
      // background-color: rgba($color: black, $alpha: 0.5);
      background-color: black;
      border-radius:  0 0 40% 40%;
      color: white;
      position: absolute;
      visibility: hidden;
      top: 135%;
      z-index: 3;
      font-size: x-small;
    }

    /* The actual popup (appears on top) */
    .popup-image {
      visibility: hidden;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 8px 0;
      position: absolute;
      z-index: 2;
      top: 135%;
      left: 50%;
      // margin-left: -250px;
    }

    /* Popup arrow */
    .popup-image::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    /* Toggle this class when clicking on the popup container (hide and show the popup) */
    .show {
      visibility: visible;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
      -moz-animation: fadeIn 1s;
      -o-animation: fadeIn 1s;
    }
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
} 

@-moz-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
} 

@-o-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
} 