#mapid {
    max-width: 100%;
    // height: 65vh;
    // border: solid;
    // border-width: thin;
    // border-color: black;

    img {
        max-width: 100%;
    }
}

.map .card-panel {
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 0;

    .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}
