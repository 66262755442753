@media only screen and (min-width: 994px) {
    nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
        height: 80px;
        line-height: 80px;
    }
}

@media only screen and (max-width: 993px) {
    /* .brand-logo {
        display: none !important;
    } */

    nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
        height: 40px;
        line-height: 40px;
    }

    .nav-wrapper > .brand-logo {
        color: black;
        font-size: 16px;
        padding-left: 0;
        line-height: 20px;
    }
}

.nav-wrapper {
    background-color:  #65ccb8;
}

@media only screen and (min-width: 994px) {
    .nav-wrapper > .brand-logo {
    color: black;
    font-size: 32px;
    padding-left: 8px;
    line-height: 40px;
}
}

.img-container{
    height: 25px;
}

/* nav ul a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1rem;
  color: #fff;
  display: block;
  padding: 0 15px;
  cursor: pointer;
} */

.link-button {
    border: none;
    background-color: transparent;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    font-size: 1rem;
    color: #fff;
    display: inline;
    padding: 0 15px;
    cursor: pointer;
}

.link-button:hover,
.link-button:focus {
    background-color: rgba(0, 0, 0, 0.1);
}

.links span{
    color: black;
}