.main-table  {
    padding: 10px;
}

.site-title {
    padding: 10px;
}

.site-details {
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #8c9eff;
}