.main-table {
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #8c9eff;
    margin-left: 10px;
    margin-right: 10px;

    .delete-additional {
        display: flex;
        justify-content: center;
    }

    td {
        border: 1px solid black;
    }
}