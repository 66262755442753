.pdf-file {
    // > * {
    //     margin-left: 25vw;
    // }
    .page-number {
        padding: 10px;
    }

    .pdf-canvas {
        height: auto;
    }
}

// .pdf-doc, .pdf-page-number {
//     > * {
//         margin-left: 10vw;
//     }
// }

// canvas {
//     width: auto !important;
//     height: 80vh !important;
// }